import React, {useState} from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import Partners from "../../static/images/icons/web/icon20.svg"
import Icon1 from "../../static/images/icons/web/icon21.svg"
import Icon2 from "../../static/images/icons/web/icon22.svg"
import Icon3 from "../../static/images/icons/web/icon23.svg"
import Icon4 from "../../static/images/icons/web/icon24.svg"
import Icon5 from "../../static/images/icons/web/icon25.svg"
import Icon6 from "../../static/images/icons/web/icon26.svg"
import { FaAngleDown } from "react-icons/fa"

const PartnerProgram = () => {
  const data = useStaticQuery(graphql`
    query ImagesPartnerProgram {
      image: allFile(
        filter: { relativeDirectory: { eq: "partners" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const [drop, setDrop] = useState(false)
  const onClick = () => {
    setDrop(!drop)
    document.getElementById("answer1").classList.toggle("show")
    document.getElementById("answer2").classList.remove("show")
    document.getElementById("answer3").classList.remove("show")    
    document.getElementById("answer4").classList.remove("show")
    document.getElementById("dropdown").classList.toggle("icon-rotate")
    document.getElementById("dropdown1").classList.remove("icon-rotate")
    document.getElementById("dropdown2").classList.remove("icon-rotate")
    document.getElementById("dropdown3").classList.remove("icon-rotate")
  }
  const [drop1, setDrop1] = useState(false)
  const onClick1 = () => {
    setDrop1(!drop1)
    document.getElementById("answer2").classList.toggle("show")
    document.getElementById("answer1").classList.remove("show")
    document.getElementById("answer3").classList.remove("show")
    document.getElementById("answer4").classList.remove("show")
    document.getElementById("dropdown1").classList.toggle("icon-rotate")
    document.getElementById("dropdown").classList.remove("icon-rotate")
    document.getElementById("dropdown2").classList.remove("icon-rotate")
    document.getElementById("dropdown3").classList.remove("icon-rotate")
  }
  const [drop2, setDrop2] = useState(false)
  const onClick2 = () => {
    setDrop2(!drop2)
    document.getElementById("answer3").classList.toggle("show")
    document.getElementById("answer1").classList.remove("show")
    document.getElementById("answer2").classList.remove("show")
    document.getElementById("answer4").classList.remove("show")
    document.getElementById("dropdown2").classList.toggle("icon-rotate")
    document.getElementById("dropdown").classList.toggle("icon-rotate")
    document.getElementById("dropdown1").classList.remove("icon-rotate")
    document.getElementById("dropdown3").classList.remove("icon-rotate")
  }
  const [drop3, setDrop3] = useState(false)
  const onClick3 = () => {
    setDrop3(!drop3)
    document.getElementById("answer4").classList.toggle("show")
    document.getElementById("answer1").classList.remove("show")
    document.getElementById("answer2").classList.remove("show")
    document.getElementById("answer3").classList.remove("show")
    document.getElementById("dropdown3").classList.toggle("icon-rotate")
    document.getElementById("dropdown").classList.remove("icon-rotate")
    document.getElementById("dropdown1").classList.remove("icon-rotate")
    document.getElementById("dropdown2").classList.remove("icon-rotate")
  }
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Partner Program | Qualoom</title>
        <meta name="title" content="Partner Program | Qualoom"/>
        <meta name="description" content="Descubre un modelo de colaboración y partnership diferente, eficaz, cuantificable y orientado al éxito y a la generación de negocio."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/partner-program/"/>
        <link rel="canonical" href="https://www.qualoom.es/partner-program/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/partner-program/"/>
        <meta property="og:title" content="Partner Program | Qualoom"/>
        <meta property="og:description" content="Descubre un modelo de colaboración y partnership diferente, eficaz, cuantificable y orientado al éxito y a la generación de negocio."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/partner-program/"/>
        <meta property="twitter:title" content="Partner Program | Qualoom"/>
        <meta property="twitter:description" content="Descubre un modelo de colaboración y partnership diferente, eficaz, cuantificable y orientado al éxito y a la generación de negocio."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3MRtNIyAwtyGPXOaftMby9/5717580e7dcee1881c0f956118deb692/tw_cards_partners.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/partner-program/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Partners y Alianzas", "item": {"@id": "https://www.qualoom.es/partner-program/", "name": "Partners y Alianzas"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-partner">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Nuestro nuevo modelo de colaboración</h1>
              <p>Descubre un modelo de colaboración y partnership diferente, eficaz, cuantificable y orientado al éxito y a la generación de negocio</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>¿Qué es el Partner Program?</h2>
          <div className="partner-program">
            <div className="partner-program-img"></div>
            <div className="partner-program-text">
              <p><b>Partner Program</b> es el programa de excelencia y colaboración creado por Qualoom Expertise Technology que <b>permite a las compañías y organizaciones</b> que forman parte del mismo, el potenciar la generación de demanda a través de una estrecha colaboración, aportando un valor añadido a las soluciones y servicios que cada uno de los miembros del canal ofrece. De esta manera <b>se consigue una ampliación exponencial del dossier de servicios ofertados</b>, creando una red multidisciplinar de soluciones y valor añadido a cada servicio de tecnología con el fin de incrementar las ventas.</p>
              <div className="partner-buttons">
                <a href="/" className="servicios-button hide">Descarga nuestra guía del<br/>Partner program</a>
                <a href="/partner-program/inscripcion/" className="servicios-button">Conoce más sobre la inscripción <br/>de nuevos miembros</a>
                <a href="/partner-program/recursos/" className="servicios-button">Conoce más sobre los recursos <br/>del Partner Program</a>
              </div>
            </div>
          </div>
          <div className="partner-program2">
            <div>
              <h3>Tu nuevo modelo de colaboración diseñado para generar negocio</h3>
              <a href="/partner-program/miembros/" className="servicios-button">Descubre todas las organizaciones y empresas que ya son miembros</a>
            </div>
            <img src={Partners} alt="Partner Program"></img>
          </div>
        </div>
      </div>
      <div className="partner-main gray">
        <div className="partner-main-content">
          <h2>Ventajas del Partner Program</h2>
          <div className="partner-adv">
            <div className="partner-adv-item"><img src={Icon1} alt="Incremento de acciones comerciales"></img><p>Ampliación del campo de acción comercial</p></div>
            <div className="partner-adv-item"><img src={Icon2} alt="Nuevos conocimientos"></img><p>Adquisición de conocimiento en materias y campos hasta ahora no explorados</p></div>
            <div className="partner-adv-item"><img src={Icon3} alt="Beneficios"></img><p>Incremento exponencial de los beneficios</p></div>
            <div className="partner-adv-item"><img src={Icon4} alt="Soporte y ayudas"></img><p>Soporte a acciones de marketing y preventa comercial</p></div>
            <div className="partner-adv-item"><img src={Icon5} alt="Recursos"></img><p>Puesta a disposición de recursos materiales y económicos</p></div>
            <div className="partner-adv-item"><img src={Icon6} alt="Asesoramiento"></img><p>Asesoramiento estratégico y tecnológico</p></div>            
          </div>
        </div>
      </div>
      <div className="partner-main">
        <div className="partner-main-content">
          <h2>Descubre más del Partner Program</h2>
          <div className="partner-question">
            <div id="open" onClick={onClick} onKeyDown aria-hidden><h3>¿Cuáles son los tipos de partners?</h3><FaAngleDown size={25} color="#3B4757" id="dropdown"/></div>
            <div id="answer1">
              <p>Actualmente el programa está enfocado en el <b>ámbito nacional español</b> pero es posible la incorporación de <b>partners internacionales.</b></p>
              <p>Existe una única modalidad de partnership “Referral”. Se trata del tipo de partnership a través del cual, Qualoom referencia a sus clientes las soluciones y servicios de miembros del canal y estos, las soluciones y servicios de Qualoom Expertise Technology, consiguiendo un comisionado en función del tipo de proyecto y servicio finalmente contratado. Este tipo de partnership va dirigido a <b>empresas especializadas en consultoría tecnología de desarrollo, seguridad de la información, infraestructura, Cloud entre otras.</b></p>
            </div>
          </div>
          <div className="partner-question">
            <div id="open" onClick={onClick1} onKeyDown aria-hidden><h3>¿Cómo seleccionamos a nuestros miembros?</h3><FaAngleDown size={25} color="#3B4757" id="dropdown1"/></div>
            <div id="answer2">
              <p>Lo más importante cuando evaluamos la incorporación de un partner al programa, <b>es identificar y confirmar cuál es su perfil, el target, el valor de ese potencial partner y el ámbito de negocio en el que se mueve</b>.</p>
              <p>Para ello <b>se mantendrán unas sesiones de trabajo que permitirán determinar la idoneidad de la membresía y la definición del perfil</b>, el cual será remitido a los responsables del Partner Program para su autorización.</p>
              <p>Para conseguir esta membresía se debe poder verificar un hecho muy sencillo y es que los clientes y socios del potencial partner, pueden llegar a ser los del resto de la red con base en:</p>
              <ul>
                <li>¿Estás preparado? Es decir, <b>se tienen recursos para invertir en la colaboración en términos de tiempo y económicos</b></li>
                <li>¿Están dispuestos? ¿La colaboración les ayudará a conseguir sus objetivos?</li>
                <li>¿Existen capacidades y cualificación?</li>
                <li>¿Existen valores similares? <b>Es importante que la cultura empresarial sea similar para poder ponerse de acuerdo</b></li>
                <li>¿Qué es importante para ambas organizaciones?</li>
                <li>¿Cuáles son los principales motores de crecimiento?</li>
                <li>¿Cuál es la propuesta de valor hacia los clientes?</li>
                <li>¿Cuál es el <b>buyer persona</b>?</li>
                <li>¿Cuáles serán los mayores obstáculos?</li>
                <li>¿Hay competencia para la oferta que queremos crear?</li>
                <li>¿Podemos destacar en el mercado? ¿Estrategias probadas?</li>
              </ul>
            </div>
          </div>
          <div className="partner-question">
            <div id="open" onClick={onClick2} onKeyDown aria-hidden><h3>¿Cómo creamos una estrategia conjunta?</h3><FaAngleDown size={25} color="#3B4757" id="dropdown2"/></div>
            <div id="answer3">
              <p>Posterior a esta autorización de membresía, <b>es esencial sentarse y plantear la estrategia: dónde queremos ir primero</b>. Los objetivos de uno y de otro, definiendo con ello los objetivos comunes y un plan. Este plan será un documento formal en el que se recopilan los puntos claves:</p>
              <p>
                <ul>
                  <li><b>Objetivos de ventas</b> (Smarts = Specific Measurable Achievable Relevant Timely) (¿Qué harán los partners y Qualoom?)</li>
                  <li><b>Estrategias/argumentario/actividades de venta</b></li>
                  <li><b>Plan de acción</b></li>
                  <li><b>Estructura de precios y comisiones</b></li>
                  <li><b>Presupuestos</b> (si fuera necesario)</li>
                  <li><b>Plan de marketing</b></li>
                </ul>
              </p>
              <p>Se trata de <b>un plan corto, sencillo, real y alcanzable</b> que permita alcanzar hitos realistas potenciando de esta manera la motivación por pertenecer al canal.</p>
              <p>Para respaldar e impulsar la aplicación de este plan, <b>se mantendrán semanal o quincenalmente reuniones con los responsables de ventas y desarrollo de negocio del Partner</b>, se realizarán pipeline reviews, gestión de las oportunidades, lanzamiento de actividades conjuntas de marketing, etc. Este seguimiento será esencial para validar si la alianza funciona, sus beneficios para ambas partes o si por el contrario es completamente estéril.</p>
            </div>
          </div>
          <div className="partner-question">
            <div id="open" onClick={onClick3} onKeyDown aria-hidden><h3>¿Cómo medimos el éxito de nuestras alianzas?</h3><FaAngleDown size={25} color="#3B4757" id="dropdown3"/></div>
            <div id="answer4">
              <p>Es importante comprender cuáles son los factores críticos que nos permitirán confirmar el éxito de la estrategia definida.</p>
              <p><ul>
                <li><b>Tipo de ciclo de venta del Partner</b></li>
                <li><b>Tipo de leads aportados</b> (cualificadas, no cualificados, etc.)</li>
                <li><b>Nº de oportunidades generadas:</b> Nº de oportunidades de negocio introducidas en el embudo de ventas en un periodo de tiempo determinado.</li>
                <li><b>Nº de ofertas realizadas:</b> Licitaciones realizadas en un período de tiempo determinado.</li>
                <li><b>Nº de demos realizadas:</b> Nº de oportunidades de negocio que llegan hasta la etapa de demostración.</li>
                <li><b>Importe  promedio por propuesta:</b> ¿Qué partners están vendiendo valor y cuáles se están vendiendo descontando los precios? ¿Cómo se compara el importe medio de ventas con los otros partners de la cartera?</li>
                <li><b>Velocidad de cierre:</b> El éxito estará cada vez más determinado por la rapidez con la que un Partner pueda atraer clientes, cerrar negocios, integrar nuestras soluciones y acumular un flujo constante de ventas/referencias recurrentes.</li>
                <li><b>Frecuencia del acuerdo:</b> ¿Qué partners realizan un esfuerzo sostenido para continuar vendiendo nuevos productos y servicios al mismo cliente? ¿Cuáles cierran las ventas y no buscan más oportunidades para vender al mismo cliente? En otras palabras, ¿El Partner se enfoca en ventas transaccionales o ventas recurrentes?</li>
                <li><b>Partners comprometidos:</b> ¿Cuántos recursos está dispuesto a poner un Partner para conseguir nuevos proyectos?</li>
              </ul></p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PartnerProgram